import { types } from "../types/types";

export const platformReducer = (state: any, action: any) => {
  switch (action.type) {
    case types.language:
      return {
        ...state,
        language: action.payload,
      };
    case types.ruta:
      return {
        ...state,
        ruta: action.payload,
      };
    case types.urlShinny:
      return {
        ...state,
        shiny: action.payload,
      };
    case types.token:
      return {
        ...state,
        token: action.payload,
      };
    case types.test:
      return {
        ...state,
        test: action.payload,
      };
    case types.dataUpdate:
      return {
        ...state,
        dataUpdate: action.payload,
      };
    case types.title:
      return {
        ...state,
        title: action.payload,
      };
    case types.dataProfile:
      return {
        ...state,
        dataProfile: action.payload,
      };
    case types.skip:
      return {
        ...state,
        skip: action.payload,
      };
    case types.limit:
      return {
        ...state,
        limit: action.payload,
      };
    case types.search:
      return {
        ...state,
        search: action.payload,
      };
    case types.company:
      return {
        ...state,
        company: action.payload,
      };
    case types.botonTitle:
      return {
        ...state,
        botonModalForm: action.payload,
      };
    case types.openModal:
      return {
        ...state,
        openModal: action.payload,
      };
    case types.uuidBoard:
      return {
        ...state,
        uuidBoard: action.payload,
      };
    case types.session:
      return {
        ...state,
        session: action.payload
      }
    case types.main:
        return {
          ...state,
          menu: action.payload
        }
    default:
      return state;
  }
};
