import React, { useContext, useEffect } from 'react';
import { createContext } from 'react';
import { useSocket } from '../hooks/useSocket'
import { types } from '../types/types';
import { PlatformContext } from './platformContext';
import { api } from '../environment';
import { useNavigate } from 'react-router-dom';


export const SocketContext = createContext();


export const SocketProvider = ({ children }) => {
   
    const socket = useSocket(api).socket;
    const online = useSocket(api).online;
    const { dispatch } = useContext(PlatformContext)

   

    useEffect(() => { 
        socket?.on('salir', (payload) =>{
    
          if(payload === "exit"){
            console.log("salir",payload)
    
            dispatch({
                type: types.token,
                payload: null
            })
            dispatch({
                type: types.session,
                payload: null
            })
            return window.location.replace("/");
          } 
    
         
        })
    },[])
    

   
    return (
        <SocketContext.Provider value={{ socket, online }}>
            { children }
        </SocketContext.Provider>
    )
}